const config = {
    languages: [
        { code: `en`, name: `English` },
        { code: `no`, name: `Norsk` },
    ],
    defaultLanguage: `no`,
    excludeNavPaths: [
        "/**/404",
        "/**/404.html",
        "/**/thankyou",
        "/dev-404-page/",
    ],
};

module.exports = config;
