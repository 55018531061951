import React from "react";
import { Link } from "gatsby";
import config from "../../config";
import Logo from "../svg/logo.svg";

const Footer = ({ lang }) => {
    return (
        <footer
            style={{
                marginTop: "auto",
                background: "var(--secondaryColor)",
                padding: "2rem",
                textAlign: "center",
                color: "var(--mainWhite)",
            }}
        >
            <div
                style={{
                    color: "white",
                    fontWeight: "normal",
                    fontSize: "1rem",
                }}
            >
                <Logo />
                <br />
                Skysstasjonen 11 B 1383 Asker Norway
                <br />
                <br />
                <span>&copy; Dynapro {new Date().getFullYear()}</span>
                <br />
                <br />
                <Link
                    to={
                        lang === config.defaultLanguage
                            ? "/privacy-policy"
                            : "/" + lang + "/privacy-policy"
                    }
                >
                    Privacy Policy
                </Link>
            </div>
        </footer>
    );
};

export default Footer;
