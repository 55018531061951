import React from "react";
import { Link } from "gatsby";
import { languages, defaultLanguage } from "../../config";

export default ({ currentLang, slug }) => {
    const targetLangs = languages
        .filter(lng => lng.code !== currentLang)
        .map(target => {
            if (target.code === defaultLanguage) {
                // target language is not prefixed with two letter language code so it is removed
                return {
                    ...target,
                    url: slug === `/${currentLang}` ? `/` : slug.substring(3),
                };
            }
            if (currentLang === defaultLanguage) {
                // current language is not prefixed with two letter language code so it is added
                return {
                    ...target,
                    url:
                        slug === `/`
                            ? `/${target.code}`
                            : `/${target.code}${slug}`,
                };
            }
            // current language code is replaced with target language code
            return { ...target, url: `/${target.code}/${slug.substring(3)}` };
        });

    return targetLangs.map(lng => {
        return (
            <li key={lng.code}>
                <Link to={lng.url}>{lng.name}</Link>
            </li>
        );
    });
};
